export const errorComsOnPlaceOrder = {
  REMOVED_PRODUCT:
    "One or more items in your cart are no longer available. They have been removed from your cart. Please review and continue.",
  DRAFT_PRODUCT:
    "Some items in your cart are temporarily unavailable. You can update your cart to proceed with your purchase.",
  MOQ_CHANGE:
    "The conditions for one or more products in your cart have changed. Please review the updated prices or quantity requirements before proceeding to checkout.",
  PRICE_CHANGE:
    "The conditions for one or more products in your cart have changed. Please review the updated prices or quantity requirements before proceeding to checkout.",
  COUPON_ARCHIVED:
    "The discount code applied to your cart is no longer valid.You can continue with the current prices or check for other offers.",
  COUPON_CHANGE:
    "One or more items in your cart are no longer eligible for the applied coupon. Please review the updated prices before proceeding to checkout.",
};
export const errorTypeOnPlaceOrder = {
  REMOVED_PRODUCT: "REMOVED_PRODUCT",
  DRAFT_PRODUCT: "DRAFT_PRODUCT",
  MOQ_CHANGE: "MOQ_CHANGE",
  PRICE_CHANGE: "PRICE_CHANGE",
  COUPON_ARCHIVED: "COUPON_ARCHIVED",
  COUPON_CHANGE: "COUPON_CHANGE",
};
